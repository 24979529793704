import { combineReducers } from 'redux';

import commonReducers from '../../common/reducers';
import lastFeaturedPost from './last-featured-post';
import archive from '../../common/store/archive/archive-reducer';
import feedSEOTags from './feed-seo-tags';

export default combineReducers({
  ...commonReducers,
  lastFeaturedPost,
  archive,
  feedSEOTags,
});
